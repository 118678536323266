import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
    sonitaUnebMathCollection,
    honitaUnebMathCollection,
    onitaTransactionsCollection,
    db
} from './firebase';
import {
    query,
    onSnapshot,
    where,
    doc,
    getDoc
} from "firebase/firestore";
import {
    getAuth
} from "firebase/auth";
Vue.use(Vuex);
export default new Vuex.Store({
    strict: true,
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ],
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        level: '',
        userDocumentId: '',
        plevelMathematics: '',
        olevelMathematics: '',
        alevelMathematics: '',
        progress: [],
        tprogress: [],
        finished_topics: [],
        balance: 0,
        balance_num: 0,
        gift: 2009,
        voucher: true,
        transactions: [],
        examinedsubject: null,
        examID: null,
        examTime: null,
        examEnded: false,
        creditID: null,
        exams: null,
        currentExam: null,
        isAnonymous: null,
        isAnonymousId: null,
        paper: null,
        pmetadata: null,
        examCost: 0
    },
    getters: {
        user(state) {
            return state.user
        },
        level(state) {
            return state.level
        },
        userDID(state) {
            return state.userDocumentId
        },
        progress(state) {
            return state.progress
        },
        tprogress(state) {
            return state.tprogress
        },
        get_finished(state) {
            return state.finished_topics
        },
        examQuestion(state) {
            return state.examQuestion;
        },
        examAnswer(state) {
            return state.examAnswer;
        },
        examQuestionSecA(state) {
            return state.examQuestionSecA;
        },
        examAnswerSecA(state) {
            return state.examAnswerSecA;
        },
        examQuestionSecB(state) {
            return state.examQuestionSecB;
        },
        examAnswerSecB(state) {
            return state.examAnswerSecB;
        },
        subjectSelected(state) {
            return state.subjectSelected;
        },
        examinedsubject(state) {
            return state.examinedsubject;
        },
        examID(state) {
            return state.examID;
        },
        examTime(state) {
            return state.examTime;
        },
        examEnded(state) {
            return state.examEnded;
        },
        creditID(state) {
            return state.creditID;
        },
        getQuestionsOlevelMathematics: (state) => {
            return qaDisplayFirestore(state)
        },
        getQuestionsAlevelMathematics: (state) => {
            return qaDisplayFirestore(state)
        },
        getBalanceNum: state => state.balance_num,
        getBalance: state => state.balance,
        getGift: state => state.gift,
        getAllTransactions: state => state.transactions,
        exams: state => state.exams,
        isAnonymous: state => state.isAnonymous,
        isAnonymousId: state => state.isAnonymousId,
        paper: state => state.paper,
        pmetadata: state => state.pmetadata,
        examCost: state => state.examCost,
    },
    mutations: {
        SET_BALANCE_NUM(state, bal) {
            state.balance_num = bal;
        },
        SET_BALANCE(state, bal) {
            state.balance = bal;
        },
        SET_VOUCHER(state, voucher) {
            state.voucher = voucher;
        },
        SET_TRANSACTIONS(state, payload) {
            state.transactions = payload
        },
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        userDocId(state, userDID) {
            state.userDocumentId = userDID
        },
        setLevel(state, payload) {
            state.level = payload;
        },
        setFinished(state, finished_topic) {
            state.finished_topics.push(finished_topic)
        },
        setProgress(state, progress) {
            state.progress.push(progress);
        },
        setTProgress(state, tprogress) {
            state.tprogress.push(tprogress);
        },
        restProgress(state) {
            state.progress = []
        },
        examQuestion(state, payload) {
            state.examQuestion = payload;
        },
        examAnswer(state, payload) {
            state.examAnswer = payload;
        },
        examQuestionSecA(state, payload) {
            state.examQuestionSecA = payload;
        },
        examAnswerSecA(state, payload) {
            state.examAnswerSecA = payload;
        },
        examQuestionSecB(state, payload) {
            state.examQuestionSecB = payload;
        },
        examAnswerSecB(state, payload) {
            state.examAnswerSecB = payload;
        },
        subjectSelected(state, payload) {
            state.subjectSelected = payload;
        },
        examinedsubject(state, payload) {
            state.examinedsubject = payload;
        },
        examID(state, payload) {
            state.examID = payload;
        },
        examTime(state, payload) {
            state.examTime = payload;
        },
        examEnded(state, payload) {
            state.examEnded = payload;
        },
        exams(state, payload) {
            state.exams = payload;
        },
        isAnonymous(state, payload) {
            state.isAnonymous = payload;
        },
        isAnonymousId(state, payload) {
            state.isAnonymousId = payload;
        },
        paper(state, payload) {
            state.paper = payload;
        },
        pmetadata(state, payload) {
            state.pmetadata = payload;
        },
        queryOlevelMathCollection: (state, payload) => {
            queryFirestore(state, payload, sonitaUnebMathCollection)
        },
        queryAlevelMathCollection: (state, payload) => {
            queryFirestore(state, payload, honitaUnebMathCollection)
        },
        queryFirestoreAndSetState: (state, topicId, targetCollection) => {
            var topicQuestions = [];
            targetCollection.orderBy("number", "asc")
                .where("topic", "array-contains", topicId)
                .get()
                .then(function (querySnapshot) {
                    if (querySnapshot.empty) {
                        alert('Topic empty!');
                    } else {
                        querySnapshot.forEach(function (doc) {
                            var question = doc.data();
                            topicQuestions.push(question);
                        });
                        state.alevelMathematics = JSON.stringify(topicQuestions);
                    }
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });

        },
        setCurrentExam: (state, payload) => {
            state.currentExam = payload;
        },
        setExamTimeLeft: (state, payload) => {
            state.examTimeLeft = payload
            window.localStorage.setItem("examTimeLeft", payload)
        },
        creditID(state, payload) {
            state.creditID = payload;
        },
        examCost(state, payload) {
            state.examCost = payload;
        },
    },
    actions: {
        fetchUser({
            commit
        }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid
                });
            } else {
                commit("SET_USER", null);
            }
        },
        userDocId(context, userDID) {
            context.commit("userDocId", userDID);
        },
        setLevel(context, payload) {
            context.commit('setLevel', payload);
        },
        setProg(context, progress) {
            context.commit('setProgress', progress)
        },
        setTProg(context, tprogress) {
            context.commit('setTProgress', tprogress)
        },
        restProgress(context) {
            context.commit("restProgress")
        },
        restTProgress(context) {
            context.commit("restTProgress")
        },
        setFinished(context, finished_topic) {
            context.commit("setFinished", finished_topic)
        },
        examQuestion(context, payload) {
            context.commit('examQuestion', payload);
        },
        examAnswer(context, payload) {
            context.commit('examAnswer', payload);
        },
        examQuestionSecA(context, payload) {
            context.commit('examQuestionSecA', payload);
        },
        examAnswerSecA(context, payload) {
            context.commit('examAnswerSecA', payload);
        },
        examQuestionSecB(context, payload) {
            context.commit('examQuestionSecB', payload);
        },
        examAnswerSecB(context, payload) {
            context.commit('examAnswerSecB', payload);
        },
        subjectSelected(context, payload) {
            context.commit('subjectSelected', payload);
        },
        examinedsubject(context, payload) {
            context.commit('examinedsubject', payload);
        },
        examID(context, payload) {
            context.commit('examID', payload);
        },
        examTime(context, payload) {
            context.commit('examTime', payload);
        },
        examEnded(context, payload) {
            context.commit('examEnded', payload);
        },
        exams(context, payload) {
            context.commit('exams', payload);
        },
        isAnonymous(context, payload) {
            context.commit('isAnonymous', payload);
        },
        isAnonymousId(context, payload) {
            context.commit('isAnonymousId', payload);
        },
        paper(context, payload) {
            context.commit('paper', payload);
        },
        pmetadata(context, payload) {
            context.commit('pmetadata', payload);
        },
        setCurrentExam: (context, payload)=>{
            context.commit('setCurrentExam', payload);
        },
        setExamTimeLeft: (context, payload) => {
            context.commit('setExamTimeLeft', payload)
            // state.examTimeLeft = payload;
        },
        creditID(context, payload) {
            context.commit('creditID', payload);
        },
        examCost(context, payload) {
            context.commit('examCost', payload);
        },
        queryOlevelMathCollection: (context, payload) => {
            context.commit('queryOlevelMathCollection', payload, sonitaUnebMathCollection)
        },
        queryAlevelMathCollection: (context, payload) => {
            context.commit('queryAlevelMathCollection', payload, honitaUnebMathCollection)
        },
        setCurrentBalance(context) {
            // 1. at first, document does not exist, so we should set balance to 0
            // 2. check if balance collection with certain document exist
            const auth = getAuth();
            getDoc(doc(db, `balance/${auth.currentUser.uid}`)).then((doc) => {
                if (doc.exists()) {
                    const bal = new Intl.NumberFormat("en-GB");
                    //match the doc balance id to the user id
                    context.commit('SET_BALANCE_NUM', doc.data().amount)
                    context.commit('SET_BALANCE', bal.format(doc.data().amount))
                    context.commit('SET_VOUCHER', bal.format(doc.data().voucher))
                } else {
                    // if the balance doc doesnt exist, set initial amount to 0 and voucher to true
                    context.commit('SET_BALANCE_NUM', 0)
                    context.commit('SET_BALANCE', 0)
                    context.commit('SET_VOUCHER', true)
                }
            });
        },
        setTransactions(context) {
            var q = query(
                onitaTransactionsCollection,
                where("userid", "==", this.state.user.data.uid)
            );
            onSnapshot(q, function (querySnapshot) {
                var arr = []
                querySnapshot.forEach(function (doc) {
                    arr.push(doc.data())
                });
                context.commit('SET_TRANSACTIONS', arr)
            });
        },
        saveAttempt(context, payload) {
            console.log(context, payload)
        }
    }
});

const qaDisplayFirestore = () => {

    const topicQuestions = JSON.parse(sessionStorage.topicQuestions);

    var topicQuestionsDisplay = topicQuestions.map(question => {
        var thisQuestion = "",
            answer = "",
            section = '',
            index = 0;

        section = question.section;
        //console.log('Sec: ', section);

        // Question
        if (section === "A")
            thisQuestion = question.question.text;
        if (section === "B") {
            var bquestion = question.question.text,
                intro = bquestion.intro,
                body = bquestion.body,
                ending = bquestion.ending,
                counter = 0;

            thisQuestion = intro;

            thisQuestion += "<ol type='a'>"
            for (counter in body)
                thisQuestion += "<li>" + body[counter] + "</li><br>";
            thisQuestion += "</ol>";

            thisQuestion += ending;
        }

        // Answer
        if (section === "A") {
            for (index in question.answer.text)
                answer += question.answer.text[index];
        }
        if (section === "B") {
            var bmethod = question.answer.text;
            answer = "<ol type='a'>";
            for (var count in bmethod) {
                answer += "<li>";
                var methods = bmethod[count];
                for (var method in methods) {
                    answer += methods[method];
                }
                answer += "</li><p/>";
            }
            answer += "</ol>";
        }
        var adisplay = JSON.parse(JSON.stringify(question)); // Deep copying array.
        adisplay.answer.text = answer;
        adisplay.question.text = thisQuestion;
        return adisplay
    });

    //Randomise questions within select topic.
    var qaDisplay = [];
    var tQuestionDisplay = JSON.parse(JSON.stringify(topicQuestionsDisplay));
    var noQuestions = topicQuestionsDisplay.length;
    for (var j = 0; j < noQuestions; j++) {
        var index = noQuestions - 1 - j;
        var i = Math.floor(Math.random() * (index));
        var selected = tQuestionDisplay[i];
        qaDisplay.push(selected);
        tQuestionDisplay.splice(selected, 1);
    }

    //console.log('qaDisplay: ',JSON.stringify(qaDisplay))
    sessionStorage.setItem('qaDisplay', JSON.stringify(qaDisplay));

    return qaDisplay;
}

const qaDisplayFirestore2 = (tQuestions) => {

    const topicQuestions = tQuestions;

    var topicQuestionsDisplay = topicQuestions.map(question => {
        var thisQuestion = "",
            answer = "",
            section = '',
            index = 0;

        section = question.section;
        //console.log('Sec: ', section);

        // Question
        if (section === "A")
            thisQuestion = question.question.text;
        if (section === "B") {
            var bquestion = question.question.text,
                intro = bquestion.intro,
                body = bquestion.body,
                ending = bquestion.ending,
                counter = 0;

            thisQuestion = intro;

            thisQuestion += "<ol type='a'>"
            for (counter in body)
                thisQuestion += "<li>" + body[counter] + "</li><br>";
            thisQuestion += "</ol>";

            thisQuestion += ending;
        }

        // Answer
        if (section === "A") {
            for (index in question.answer.text)
                answer += question.answer.text[index];
        }
        if (section === "B") {
            var bmethod = question.answer.text;
            answer = "<ol type='a'>";
            for (var count in bmethod) {
                answer += "<li>";
                var methods = bmethod[count];
                for (var method in methods) {
                    answer += methods[method];
                }
                answer += "</li><p/>";
            }
            answer += "</ol>";
        }
        var adisplay = JSON.parse(JSON.stringify(question)); // Deep copying array.
        adisplay.answer.text = answer;
        adisplay.question.text = thisQuestion;
        return adisplay
    });

    //Randomise questions within select topic.
    var qaDisplay = [];
    var tQuestionDisplay = JSON.parse(JSON.stringify(topicQuestionsDisplay));
    var noQuestions = topicQuestionsDisplay.length;
    for (var j = 0; j < noQuestions; j++) {
        var index = noQuestions - 1 - j;
        var i = Math.floor(Math.random() * (index));
        var selected = tQuestionDisplay[i];
        qaDisplay.push(selected);
        tQuestionDisplay.splice(selected, 1);
    }

    //console.log('qaDisplay: ', JSON.stringify(qaDisplay))
    sessionStorage.setItem('qaDisplay', JSON.stringify(qaDisplay));

    return qaDisplay;
}

const queryFirestore = (state, topicId, targetCollection) => {
    var topicQuestions = [];

    targetCollection.orderBy("number", "asc")
        .where("topic", "array-contains", topicId)
        .get()
        .then(function (querySnapshot) {
            if (querySnapshot.empty) {
                sessionStorage.setItem('empty', JSON.stringify(true));
                alert('Topic empty!');
            } else {
                querySnapshot.forEach(function (doc) {
                    var question = doc.data();
                    //console.log(doc.id, " => ", question);
                    topicQuestions.push(question);
                });
                sessionStorage.setItem('topicQuestions', JSON.stringify(topicQuestions));
                qaDisplayFirestore2(topicQuestions);
                sessionStorage.setItem('fbIsLoaded', JSON.stringify(true));
            }
        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}
