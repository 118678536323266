import Vue from "vue";
import App from "./App.vue";

import VueResource from "vue-resource";

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import store from "./store/store";

import Flutterwave from "flutterwave-vue-v3";

Vue.use(Flutterwave, {
  publicKey: "FLWPUBK-5b11d1bf505fc58bc28bd1c574ad6cdd-X",
});

Vue.config.productionTip = true;

import "vuetify/dist/vuetify.min.css";

import router from "./router";

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.base, // #F44336
        secondary: colors.red.lighten2, // #FFCDD2
        accent: colors.lightBlue.accent3, // #3F51B5
      },
      dark: {},
    },
  },
  icons: {
    iconfont: "md",
  },
});

import {
  auth
} from "./store/firebase";
import {
  onAuthStateChanged
} from "firebase/auth"

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch("fetchUser", user);
  }
});

Vue.use(VueResource);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        next();
      } else {
        next({
          path: "home",
        });
      }
    });
  } else {
    next();
  }
});

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");