import {
  initializeApp
} from "firebase/app";
import {
  getAnalytics,
  logEvent
} from "firebase/analytics";
import {
  getAuth
} from "firebase/auth";
import {
  //getFirestore,
  collection,
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED
} from "firebase/firestore";
import "firebase/storage";
import {
  getDatabase
} from "firebase/database";
import {
  getFunctions
} from "firebase/functions";
import {
  getPerformance
} from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyAd2yjOEUADDMWxQx4W2C1hoXhGco_3agQ",
  authDomain: "onitaes.firebaseapp.com",
  databaseURL: "https://onitaes.firebaseio.com",
  projectId: "onitaes",
  storageBucket: "onitaes.appspot.com",
  messagingSenderId: "42080057964",
  appId: "1:42080057964:web:459a11749c41de6a917649",
  measurementId: "G-7E3D2DC9G4"
};

const app = initializeApp(firebaseConfig)
import {
  initializeAppCheck,
  ReCaptchaV3Provider
} from "firebase/app-check";

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LecEC4jAAAAAPngG40IbTggiVtWC-RuVGBYx6hW'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export const analytics = getAnalytics(app);
logEvent(analytics, "sign_up", {
  method: "Google"
});
logEvent(analytics, "login", {
  method: "Google"
});
export const perf = getPerformance(app);
//export const db = getFirestore(app);
export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  //experimentalAutoDetectLongPolling: true
});
export const rdb = getDatabase(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

//Primary Level
export const ponitaUnebMathCollection = collection(db, "ponita/uneb/mathematics");
export const ponitaUnebSciCollection = collection(db, "ponita/uneb/science");

// Ordinary Level
export const sonitaUnebMathCollection = collection(db, "sonita/uneb/mathematics");

// Advanced Level
export const honitaUnebMathCollection = collection(db, "honita/uneb/mathematics");
export const honitaUnebHisCollection = collection(db, "honita/uneb/ahistory");
export const honitaUnebCheCollection = collection(db, "honita/uneb/chemistry");

//Examination
export const examCollection = collection(db, "exams");
// balance
const balanceRef = collection(db, "balance");
export const onitaBalanceCollection = balanceRef;

// transactions
const transactionsRef = collection(db, "transactions");
export const onitaTransactionsCollection = transactionsRef;

//user
const usersRef = collection(db, "users");
export const onitaUsersCollection = usersRef;

//progress
const progressRef = collection(db, "progress");
export const progressCollection = progressRef;