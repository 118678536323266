import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    routes: [{
            path: "/",
            component: () => import( /* webpackChunkName: "home" */ "../components/OnitaHome"),
            name: "home",
            meta: {
                state: true
            }
        }, {
            path: '/db/:levelId',
            component: () => import( /* webpackChunkName: "leveldashboard" */ "../components/front/OnitaLevelDashboard"),
            name: "leveldashboard"
        }, {
            path: '/paper/:year/:code',
            component: () => import( /* webpackChunkName: "browsepaper" */ "../components/front/OnitaBrowsePaper"),
            name: "browsepaper"
        },
        {
            path: "/signup",
            component: () => import( /* webpackChunkName: "signup" */ "../components/auth/OnitaSignup"),
            name: "signup"
        }, {
            path: "/signin",
            component: () => import( /* webpackChunkName: "signin" */ "../components/auth/OnitaSignin"),
            name: "signin"
        }, 
        {
            path: "/verify",
            component: () => import( /* webpackChunkName: "verify" */ "../components/auth/OnitaVerifyEmail"),
            name: "verify"
        },
         {
            path: "/forgotpassword",
            component: () => import( /* webpackChunkName: "signin" */ "../components/auth/OnitaForgotPassword"),
            name: "forgotpassword"
        },
        {
            path: "/dashboard",
            component: () => import( /* webpackChunkName: "dashboard" */ "../components/OnitaUserDashboard"),
            name: "dashboard",
            meta: {
                auth: true
            },
            children: [{
                    path: '',
                    component: () => import( /* webpackChunkName: "mydashboard" */ "../components/OnitaMyDashboard"),
                    name: "mydashboard",
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'mysubjects',
                    component: () => import( /* webpackChunkName: "mysubjects" */ "../components/OnitaMySubjects"),
                    name: "mysubjects",
                    meta: {
                        auth: true
                    },
                    children: [{
                        path: ':selectedsubject',
                        component: () => import( /* webpackChunkName: "selectedsubject" */ "../components/OnitaSelectedSubject"),
                        name: "selectedsubject",
                        meta: {
                            auth: true
                        }
                    }]
                },
                {
                    path: 'myprogress',
                    component: () => import( /* webpackChunkName: "myprogress" */ "../components/OnitaMyProgress"),
                    name: "myprogress",
                    meta: {
                        auth: true
                    },
                    children: [{
                        path: ':selectedsubjectprogress',
                        component: () => import( /* webpackChunkName: "selectedsubjectprogress" */ "../components/OnitaSelectedSubjectProgress"),
                        name: "selectedsubjectprogress",
                        meta: {
                            auth: true
                        }
                    }]
                },
                {
                    path: 'myaccount',
                    component: () => import( /* webpackChunkName: "myaccount" */ "../components/OnitaMyAccount"),
                    name: "myaccount",
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'myteachers',
                    component: () => import( /* webpackChunkName: "myteachers" */ "../components/OnitaMyTeachers"),
                    name: "myteachers",
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'mywallet',
                    component: () => import( /* webpackChunkName: "mywallet" */ "../components/wallet/OnitaWallet"),
                    name: "mywallet",
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'examination',
                    component: () => import( /* webpackChunkName: "examination" */ "../components/examination/OnitaExamination"),
                    name: "examination",
                    meta: {
                        auth: true
                    },
                    children: [{
                        path: ':examsubj',
                        component: () => import( /* webpackChunkName: "examsubj" */ "../components/examination/OnitaExamination"),
                        name: "examsubj",
                        meta: {
                            auth: true
                        }
                    }]
                },
                {
                    path: 'history',
                    component: () => import( /* webpackChunkName: "history" */ "../components/OnitaHistory"),
                    name: "history",
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'administration',
                    component: () => import( /* webpackChunkName: "administration" */ "../components/OnitaAdministration"),
                    name: "administration",
                    meta: {
                        auth: true
                    },
                }
            ]
        },
        /*{
            path: '/dashboard/mysubjects/:subject/:topic',
            component: OnitaQuestionAndAnswer,
            name: "questionanswer",
            meta: {
                auth: true
            },
        },*/
        {
            path: '/dashboard/mysubjects/:selectedsubject/:topic',
            component: () => import( /* webpackChunkName: "qadashboard" */ "../components/examination/OnitaQuestionAndAnswerDashboard"),
            name: "qadashboard",
            meta: {
                auth: true
            }
        },
        {
            path: '/dashboard/mysubjects/:selectedsubject/:topic/question',
            component: () => import( /* webpackChunkName: "question" */ "../components/examination/OnitaQuestion"),
            name: "question",
            meta: {
                auth: true
            }
        },
        {
            path: '/dashboard/mysubjects/:selectedsubject/:topic/answer',
            component: () => import( /* webpackChunkName: "answer" */ "../components/examination/OnitaAnswer"),
            name: "answer",
            meta: {
                auth: true
            }
        },
        {
            path: '/examdisplay/:examinedsubject',
            component: () => import( /* webpackChunkName: "examdisplay" */ "../components/examination/OnitaExaminationDisplay"),
            name: "examdisplay",
            meta: {
                auth: true
            }
        },
        {
            path: '/answersdisplay/:examinedsubject',
            component: () => import( /* webpackChunkName: "examdisplay" */ "../components/examination/OnitaAnswersDisplay"),
            name: "answersdisplay",
            meta: {
                auth: true
            }
        },
        // {
        //     path: '/examinstruction',
        //     component: () => import( /* webpackChunkName: "examinstruction" */ "../components/examination/OnitaExaminationInstruction"),
        //     name: "examinstruction",
        //     meta: {
        //         auth: true
        //     }
        // },        
        {
            path: "*",
            redirect: "/"
        }
    ]
})
