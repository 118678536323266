<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import store from "./store/store";
export default {
  name: "App",
  components: {},
  data: () => ({}),
  destroyed() {
    if (store.state.isAnonymous) {
      store.dispatch("isAnonymous", null); // reset isAnonymous
      store.dispatch("isAnonymousId", null); // reset isAnonymous
      if (firebase.auth().currentUser != null) {
        var emailVerified = firebase.auth().currentUser.emailVerified;
        var userData = firebase.auth().currentUser.providerData;
        if (!emailVerified && userData.length == 0) {
          firebase.auth().currentUser.delete();
        }
      }
    }
  },
};
</script>
<style>
div.hscroll {
  display: flex;
  overflow-x: auto;
}
div.onita-ui-display {
  margin: 1.25% 4.16% 1.25% 4.16% !important;
}
@media only screen and (min-device-pixel-ratio: 1.5),
  only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  div {
    font-size: 16px !important;
    line-height: 150% !important;
  }
}
@media only screen and (min-device-pixel-ratio: 2),
  only screen and (-webkit-min-device-pixel-ratio: 2) {
  div {
    font-size: 18px !important;
    line-height: 150% !important;
  }
}
@media (min-width: 600px) {
  div {
    font-size: 1.025rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 1.25% 4.16% 1.25% 4.16% !important;
  }
}
@media (min-width: 750px) {
  div {
    font-size: 1.05rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 8.32% 2.5% 8.32% !important;
  }
}
@media (min-width: 900px) {
  div {
    font-size: 1.075rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 12.48% 2.5% 12.48% !important;
  }
}
@media (min-width: 1050px) {
  div {
    font-size: 1.1rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.66% 2.5% 16.66% !important;
  }
}
@media (min-width: 1200px) {
  div {
    font-size: 1.125rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.64 2.5% 16.66% !important;
  }
}
@media (min-width: 1350px) {
  div {
    font-size: 1.15rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.66% 2.5% 16.66% !important;
  }
}
@media (min-width: 1500px) {
  div {
    font-size: 1.175rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.66% 2.5% 16.66% !important;
  }
}
@media (min-width: 1650px) {
  div {
    font-size: 1.2rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.66% 2.5% 16.66% !important;
  }
}
@media (min-width: 1800px) {
  div {
    /* font-size: 1.225rem !important; */
    font-size: 1.5rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 16.66% 2.5% 16.66% !important;
  }
}
@media (min-width: 1950px) {
  div {
    /* font-size: 1.25rem !important; */
    font-size: 1.75rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 2100px) {
  div {
    /* font-size: 1.275rem !important; */
    font-size: 2.0rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 2250px) {
  div {
    /* font-size: 1.3rem !important; */
    font-size: 2.25rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 2400px) {
  div {
    /*font-size: 1.325rem !important;*/
    font-size: 2.5rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 3000px) {
  div {
    font-size: 2.75rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 4000px) {
  div {
    font-size: 3.0rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
@media (min-width: 5000px) {
  div {
    font-size: 3.25rem !important;
    line-height: 150% !important;
  }
  div.onita-ui-display {
    margin: 2.5% 20.8% 2.5% 20.8% !important;
  }
}
div.nocopy {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
div.word-break {
  word-break: normal;
}
div.white-space {
  white-space: normal;
}
.nocopy {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.word-break {
  word-break: normal;
}
.white-space {
  white-space: normal;
}
.img-responsive {
  max-width: 100%;
  min-width: 256px;
  height: auto;
}
</style>
